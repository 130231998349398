import styles from './lakimi-chatbot-header.css?raw';

const template = document.createElement('template');
template.innerHTML = `<style>${styles}</style>
<img style="display:none" src="./avatar.png" class="chatbot-avatar">
<div class="chatbot-name">Chatbot</div>
`;

export class LakimiChatbotHeader extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.nameElement = this.shadowRoot.querySelector('.chatbot-name');
        this.avatarElement = this.shadowRoot.querySelector('.chatbot-avatar');
    }

    static get observedAttributes() {
        return ['avatar', 'name'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'avatar') {
            if (newValue) {
                this.avatarElement.src = newValue;
                this.avatarElement.style.display = 'block';
            } else {
                this.avatarElement.style.display = 'none';
            }
        } else if (name === 'name') {
            this.nameElement.innerText = newValue;
        }
    }
}

customElements.define('lakimi-chatbot-header', LakimiChatbotHeader);