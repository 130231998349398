import styles from './lakimi-attached-file.css?raw';

const template = document.createElement('template');
template.innerHTML = `<style>${styles}</style>
<div class="attached-file">
    <div class="icon-container">
        <iconify-icon icon="teenyicons:pdf-outline"></iconify-icon>
    </div>
    <div class="file-info">
        <div class="file-name">file.pdf</div>
        <div class="file-type">PDF</div>
    </div>
    <button class="btn-remove">
        <iconify-icon icon="mdi:remove"></iconify-icon>
    </button>
<div>`;

export class LakimiAttachedFile extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.iconElement = this.shadowRoot.querySelector('.icon-container iconify-icon');
        this.nameElement = this.shadowRoot.querySelector('.file-name');
        this.typeElement = this.shadowRoot.querySelector('.file-type');
        this.buttonRemoveElement = this.shadowRoot.querySelector('.btn-remove');

        this.buttonRemoveElement.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('remove', { detail: this.getAttribute('data-file') }));
        });
    }

    static get observedAttributes() {
        return ['data-type', 'data-name', 'data-uploading', 'data-deletable'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue) {
            return;
        }
        switch (name) {
            case 'data-deletable':
                if (newValue === 'true') {
                    this.buttonRemoveElement.style.display = 'block';
                } else {
                    this.buttonRemoveElement.style.display = 'none';
                }
                break;
            case 'data-type':
                let isUploading = this.getAttribute('data-uploading') === 'true';
                if (isUploading) {
                    return;
                }
                switch (newValue) {
                    case 'application/pdf':
                        this.iconElement.setAttribute('icon', 'teenyicons:pdf-outline');
                        this.typeElement.textContent = 'PDF';
                        break;
                    default:
                        this.iconElement.setAttribute('icon', 'teenyicons:file-outline');
                        this.typeElement.textContent = 'Documento';
                        break;
                }
                break;
            case 'data-name':
                this.shadowRoot.querySelector('.file-name').textContent = newValue;
                break;
            case 'data-uploading':
                if (newValue === 'true') {
                    this.buttonRemoveElement.style.display = 'none';
                    this.iconElement.setAttribute('icon', 'line-md:uploading-loop');
                } else {
                    this.buttonRemoveElement.style.display = 'block';
                    this.attributeChangedCallback('data-type', null, this.getAttribute('data-type'));
                }
                break;
        }
    }
}

customElements.define('lakimi-attached-file', LakimiAttachedFile);
