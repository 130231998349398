

import * as marked from "marked";
import styles from './lakimi-chatbot-messages.css?raw';

const template = document.createElement('template');
template.innerHTML = `
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.10/dist/katex.min.css">
<style>${styles}</style>
`;

export class LakimiChatbotMessages extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  _onAddMessage = null;

  async addMessage(message) {
    console.log('Adding message', message)
    const isAssistant = message.role === 'assistant';
    const messageElement = document.createElement('div');
    messageElement.classList.add('chatbot-message');
    let subbmited = false;

    if (message.type === 'document') {
      messageElement.classList.add('chatbot-message-document');
      const documentElement = document.createElement('lakimi-attached-file');
      documentElement.setAttribute('data-file', message.content.id);
      documentElement.setAttribute('data-name', message.content.filename);
      documentElement.setAttribute('data-type', message.content.type);
      documentElement.setAttribute('data-deletable', 'false');

      messageElement.appendChild(documentElement);
    } else if (message.type === 'text') {
      messageElement.classList.add('markdown', isAssistant ? 'chatbot-message-bot' : 'chatbot-message-user');
      const preprocessedText = message.content.replace(/\\\[/gsi, '$$')
        .replace(/\\\]/gsi, '$$')
        .replace(/\\\(/gsi, '$')
        .replace(/\\\)/gsi, '$');
      messageElement.innerHTML = marked.parse(preprocessedText);
    } else if (message.type === 'component') {
      switch (message.content.type) {
        case 'text':
          {
            messageElement.classList.add('markdown', isAssistant ? 'chatbot-message-bot' : 'chatbot-message-user');
            const preprocessedText = message.content.args.text.replace(/\\\[/gsi, '$$')
              .replace(/\\\]/gsi, '$$')
              .replace(/\\\(/gsi, '$')
              .replace(/\\\)/gsi, '$');
            messageElement.innerHTML = marked.parse(preprocessedText);
          }
          break;
        case 'select':
          {
            messageElement.classList.add('chatbot-message-list');
            if (message.content.args.label) {
              const labelElement = document.createElement('div');
              labelElement.classList.add('list-label');
              labelElement.textContent = message.content.args.label;
              messageElement.appendChild(labelElement);
            }
            const listElement = document.createElement('ul');
            for (const option of message.content.args.options) {
              const optionElement = document.createElement('li');
              optionElement.textContent = option.label;
              optionElement.addEventListener('click', () => {
                subbmited = true;
                const event = new CustomEvent('interaction', {
                  detail: {
                    component_id: message.content.id,
                    action: 'select_option',
                    content: option.value,
                  }
                });
                this.dispatchEvent(event);
                listElement.remove();
                // append selected option to message
                const selectedOptionElement = document.createElement('div');
                selectedOptionElement.classList.add('selected-option');
                selectedOptionElement.textContent = option.label;
                messageElement.appendChild(selectedOptionElement);
              });
              listElement.appendChild(optionElement);
            }
            messageElement.appendChild(listElement);
            this._onAddMessage = () => {
              if (!subbmited) messageElement.remove();
            }
          }
          break;
        case 'form':
          {
            messageElement.classList.add('chatbot-message-form');
            const formElement = document.createElement('form');

            formElement.addEventListener('submit', (formEvent) => {
              formEvent.preventDefault();
              subbmited = true;
              const formData = new FormData(formElement);
              const data = {};
              for (const [key, value] of formData.entries()) {
                data[key] = value;
              }
              const event = new CustomEvent('interaction', {
                detail: {
                  component_id: message.content.id,
                  action: 'submit_form',
                  content: data,
                }
              });
              this.dispatchEvent(event);

              formElement.remove();

              // render each field as name & value in list (ul / li)
              const formValuesElement = document.createElement('ul');
              formValuesElement.classList.add('form-values');
              for (const [name, value] of formData.entries()) {
                const formValueElement = document.createElement('li');
                const label = message.content.args.fields.find(field => field.name === name)?.label || name;
                const bold = document.createElement('b');
                bold.textContent = label + ': ';
                formValueElement.appendChild(bold);
                const span = document.createElement('span');
                span.textContent = value;
                formValueElement.appendChild(span);
                formValuesElement.appendChild(formValueElement);
              }
              messageElement.appendChild(formValuesElement);
            });

            for (const field of message.content.args.fields) {
              const formGroupElement = document.createElement('div');
              formGroupElement.classList.add('form-group');

              if (field.label) {
                const labelElement = document.createElement('label');
                labelElement.textContent = field.label;
                formGroupElement.appendChild(labelElement);
              }

              switch (field.type) {
                case 'text':
                  const fieldElement = document.createElement('input');
                  fieldElement.type = 'text';
                  if (field.placeholder) fieldElement.placeholder = field.placeholder;
                  fieldElement.required = field.required;
                  fieldElement.name = field.name;
                  formGroupElement.appendChild(fieldElement);
                  break;
                case 'select':
                  const selectElement = document.createElement('select');
                  selectElement.name = field.name;
                  for (const option of field.options) {
                    const optionElement = document.createElement('option');
                    optionElement.value = option.value;
                    optionElement.text = option.label;
                    selectElement.appendChild(optionElement);
                  }
                  formGroupElement.appendChild(selectElement);
                  break;
                case 'datepicker':
                  const datepickerElement = document.createElement('input');
                  datepickerElement.type = 'date';
                  datepickerElement.name = field.name;
                  formGroupElement.appendChild(datepickerElement);
                  break;
              }

              if (field.help_text) {
                const helpTextElement = document.createElement('small');
                helpTextElement.textContent = field.help_text;
                formGroupElement.appendChild(helpTextElement);
              }

              formElement.appendChild(formGroupElement);
            }
            const submitButton = document.createElement('button');
            submitButton.type = 'submit';
            submitButton.textContent = message.content.args.submit_label;
            formElement.appendChild(submitButton);

            messageElement.appendChild(formElement);

            this._onAddMessage = () => {
              if (!subbmited) messageElement.remove();
            }
          }
          break;
      }
    }

    this.shadowRoot.appendChild(messageElement);
    this.scrollTop = this.scrollHeight;
  }

  clearMessages() {
    const messages = this.shadowRoot.querySelectorAll('.chatbot-message');
    messages.forEach(message => message.remove());
  }
}

customElements.define('lakimi-chatbot-messages', LakimiChatbotMessages);