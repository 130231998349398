const template = document.createElement('template');
template.innerHTML = `Loading...`;

export class LakimiLoader extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(template.content.cloneNode(true));
    }
}
customElements.define('lakimi-loader', LakimiLoader);