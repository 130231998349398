import { io } from 'socket.io-client';

import styles from './chatbot.css?raw'
const DEFAULT_ENDPOINT = 'https://lakimi.feimsoft.com/api/';

const chatTemplate = document.createElement('template');
chatTemplate.innerHTML = `
<style>${styles}</style>
<div class="chatbot-container">
  <lakimi-chatbot-header></lakimi-chatbot-header>
  <div class="messages-container">
    <lakimi-chatbot-messages></lakimi-chatbot-messages>
  </div>
  <lakimi-chatbot-prompt></lakimi-chatbot-prompt>
</div>`;

class LakimiChat extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(chatTemplate.content.cloneNode(true));
    this.initializeElements();
  }

  initializeElements() {
    this.containerElement = this.shadowRoot.querySelector('.chatbot-container');
    this.messagesContainerElement = this.shadowRoot.querySelector('.messages-container');
    this.headerElement = this.shadowRoot.querySelector('lakimi-chatbot-header');
    this.messagesElement = this.shadowRoot.querySelector('lakimi-chatbot-messages');
    this.promptElement = this.shadowRoot.querySelector('lakimi-chatbot-prompt');

    this.promptElement.addEventListener('prompt', (event) => this.handlePrompt(event));
    this.promptElement.addEventListener('voice', (event) => this.handleVoice(event));
    this.messagesElement.addEventListener('interaction', (event) => this.handleUserInteraction(event));
  }

  toggleLoading(show) {
    this.containerElement.style.display = show ? 'none' : 'flex';

    if (show) {
      const loaders = this.shadowRoot.querySelectorAll('lakimi-loader');
      if (loaders.length > 0) return;
      const loader = document.createElement('lakimi-loader');
      this.shadowRoot.appendChild(loader);
    } else {
      const loaders = this.shadowRoot.querySelectorAll('lakimi-loader');
      loaders.forEach(loader => loader.remove());
    }

  }

  async connectedCallback() {
    this.conversationId = null;

    this.toggleLoading(true);
    this.initializeSocket();
  }

  get apiEndpoint() {
    return this.getAttribute('data-api-endpoint') ?? DEFAULT_ENDPOINT;
  }

  static get observedAttributes() {
    return ['data-assistant', 'data-token', 'data-api-endpoint', 'data-language'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'data-api-endpoint') {
      this.promptElement.setAttribute('data-api-endpoint', newValue);
    }
    if (['data-assistant', 'data-token', 'data-language'].includes(name)) {
      this.conversationId = null;
      this.toggleLoading(true);
      this.initializeSocket();
    }
  }

  initializeSocket() {
    if (this.getAttribute('data-assistant') === null) {
      return;
    }

    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = io(`${this.apiEndpoint}/assistant`);
    this.socket.on('connect', () => this.onSocketConnect());
    this.socket.on('message', (message) => this.messagesElement.addMessage(message));
    this.socket.on('error', (error) => {
      console.error('Assistant error', error)
      if (error.code === 429) {
        this.messagesElement.addMessage({ role: 'assistant', type: 'text', content: { text: 'Sorry, I am currently busy. Please try again later.' } });
      }
    });
    this.socket.on('action', (action) => {
      this.dispatchEvent(new CustomEvent('action', { detail: action }));
    });
    this.socket.on('status', (status) => this.handleStatus(status));
    this.socket.on('load', (data) => this.handleLoad(data));
    this.socket.on('assistant-voice', (buffer) => {
      const audioCtx = new AudioContext();
      audioCtx.decodeAudioData(buffer, (audioBuffer) => {
        const source = audioCtx.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioCtx.destination);
        source.start();
        this.assistantSpeakSource = source;
      });
    });
  }

  handlePrompt(event) {
    this.sendMessage(event.detail);
  }

  handleUserInteraction(event) {
    this.socket.emit('interaction', event.detail);
  }

  async stopAssistantSpeak() {
    this.assistantSpeakSource?.disconnect();
    this.assistantSpeakSource = null;
  }

  async handleVoice() {
    if (this.voiceEngine) {
      this.voiceEngine.stream.getTracks().forEach(track => track.stop());
      this.voiceEngine.audioWorkletNode.disconnect();
      this.voiceEngine.source.disconnect();
      await this.voiceEngine.audioCtx.close();
      this.voiceEngine = null;
      this.socket.emit('stop-conversation');
      return;
    }

    this.socket.emit('start-conversation')

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioCtx = new AudioContext({ sampleRate: 16000 });
    const source = audioCtx.createMediaStreamSource(stream);
    await audioCtx.audioWorklet.addModule(`${this.apiEndpoint}/linear-pcm-processor.js`);
    const audioWorkletNode = new AudioWorkletNode(audioCtx, "linear-pcm-processor");
    source.connect(audioWorkletNode);
    audioWorkletNode.connect(audioCtx.destination);
    audioWorkletNode.port.onmessage = (e) => {
      const buffer = e.data;
      this.socket.emit('conversation-voice', buffer);
    };

    this.voiceEngine = { stream, audioCtx, source, audioWorkletNode };
  }

  sendMessage(prompt) {
    if (prompt) {
      const messages = [];
      for (const file of prompt.files) {
        const msg = { type: 'document', content: file };
        messages.push(msg);
        this.messagesElement.addMessage(msg);
      }
      const userPrompt = { type: 'text', content: prompt.message };
      messages.push(userPrompt);
      this.messagesElement.addMessage(userPrompt);

      this.socket.emit('prompt', messages);
    }
  }

  handleStatus(status) {
    let statusElement = this.shadowRoot.querySelector('lakimi-status');

    if (!statusElement) {
      statusElement = document.createElement('lakimi-status');
      this.messagesContainerElement.appendChild(statusElement);
    }

    if (status === 'speaking') {
      this.stopAssistantSpeak();
    }

    if (['typing', 'thinking'].includes(status)) {
      statusElement.setAttribute('status', status);
    } else {
      statusElement.remove();
    }
  }

  handleLoad(data) {
    console.log('Assistant load', data);
    if (!this.conversationId || this.conversationId !== data.conversation) {
      this.messagesElement.clearMessages();
      this.conversationId = data.conversation;
      this.promptElement.setAttribute('data-conversation', data.conversation);
    }
    this.headerElement.setAttribute('name', data.name);
    if (!data.avatar) {
      this.headerElement.removeAttribute('avatar');
    } else {
      this.headerElement.setAttribute('avatar', data.avatar);
    }
    this.promptElement.setPromptPlaceholder(data.literals.type_message);
    this.promptElement.toggleAttachments(data.enable_attachments);
    this.promptElement.toggleVoice(data.enable_voice);
    this.toggleLoading(false);
  }

  onSocketConnect() {
    const assistantId = this.getAttribute('data-assistant');
    const token = this.getAttribute('data-token');
    const language = this.getAttribute('data-language') ?? 'es';
    this.socket.emit('load', { id: assistantId, token: token, conversation: this.conversationId, language });
  }
}

customElements.define('lakimi-chat', LakimiChat);
