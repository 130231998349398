import styles from './lakimi-status.css?raw';
const template = document.createElement('template');
template.innerHTML = `<style>${styles}</style>
<div class="circle circle-a"></div><div class="circle circle-b"></div><div class="circle circle-c"></div>`;

export class LakimiStatus extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  static get observedAttributes() {
    return ['status'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'status') {
      this.classList.remove('lakimi-status-typing');
      this.classList.remove('lakimi-status-thinking');
      this.classList.add(`lakimi-status-${newValue}`);
    }
  }
}

window.customElements.define('lakimi-status', LakimiStatus);