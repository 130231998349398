import 'iconify-icon';
import 'katex';
import * as marked from "marked";
import markedKatex from 'marked-katex-extension';

import './chatbot.js';
import './lakimi-status.js'
import './lakimi-loader.js'
import './lakimi-chatbot-header.js'
import './lakimi-chatbot-prompt.js'
import './lakimi-chatbot-messages.js';

marked.use(markedKatex({ throwOnError: false }));