import React, { useEffect, useRef, useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import '@lakimi/chatbot';
import { Box, Tabs, Tab } from '@mui/material';

function App() {
  const s = new URLSearchParams(document.location.search);
  const assistantId = s.get('id');
  const chatbotRef = useRef(null);
  const [actions, setActions] = useState([]);
  const [language, setLanguage] = useState('es');

  useEffect(() => {
    if (!chatbotRef.current) return;
    const element = chatbotRef.current;
    const callback = (event) => {
      setActions((x) => ([...x, event.detail]))
    };
    element.addEventListener('action', callback);
    return () => {
      element.removeEventListener('action', callback);
    }
  }, [chatbotRef]);

  return (
    <Box sx={{
      width: '100%', height: '100%', display: 'flex', flexDirection: 'column'
    }}>
      <Tabs value={language} onChange={(_, v) => setLanguage(v)}>
        <Tab label="Español" value="es" />
        <Tab label="Català" value="ca" />
        <Tab label="English" value="en" />
      </Tabs>

      <div className="chatbot-container">
        <lakimi-chat
          id="assistant"
          ref={chatbotRef}
          data-api-endpoint={s.get('api')}
          data-language={language}
          data-assistant={assistantId}
        />
        <div className="log-actions" style={{ display: 'none' }}>
          <p>Listado de acciones ejecutadas</p>
          <ul>
            {actions.map((action, index) => (
              <li key={index}>
                <strong>Action:</strong> {action.type}
                <br />
                <pre>{JSON.stringify(action.args, null, 2)}</pre>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Box>
  );
}

export default App;
