import './lakimi-attached-file.js';
import styles from './lakimi-chatbot-prompt.css?raw';

const template = document.createElement('template');
template.innerHTML = `<style>${styles}</style>

<div class="attached-files empty"></div>

<div class="prompt-bar">
    <input multiple type="file" tabindex="-1" style="display: none;">
    <button class="chatbot-attach">
        <iconify-icon icon="ion:attach"></iconify-icon>
    </button>
     <button class="chatbot-voice">
        <iconify-icon icon="icon-park-outline:voice"></iconify-icon>
    </button>
    <input type="text" class="chatbot-input" placeholder="Type a message...">
    <button class="chatbot-send">
        <iconify-icon icon="ph:arrow-up"></iconify-icon>
    </button>
</div>
`;

export class LakimiChatbotPrompt extends HTMLElement {
    constructor() {
        super();
        this.attachedFiles = [];
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.initializeElements();
    }

    initializeElements() {
        this.inputFilesElement = this.shadowRoot.querySelector('input[type="file"]');
        this.chatbotInputElement = this.shadowRoot.querySelector('.chatbot-input');
        this.chatbotSendElement = this.shadowRoot.querySelector('.chatbot-send');
        this.chatbotAttachButton = this.shadowRoot.querySelector('.chatbot-attach');
        this.chatbotVoiceButton = this.shadowRoot.querySelector('.chatbot-voice');
        this.attachedFilesElement = this.shadowRoot.querySelector('.attached-files');

        this.inputFilesElement.addEventListener('change', this.handleSelectedFiles.bind(this));
        this.chatbotSendElement.addEventListener('click', this.sendMessage.bind(this));
        this.chatbotAttachButton.addEventListener('click', this.attachFiles.bind(this));
        this.chatbotInputElement.addEventListener('keypress', this.handleKeyPress.bind(this));
        this.chatbotVoiceButton.addEventListener('click', this.handleActiveVoice.bind(this));
    }

    get apiEndpoint() {
        return this.getAttribute('data-api-endpoint') ?? DEFAULT_ENDPOINT;
    }

    toggleAttachments(show) {
        this.chatbotAttachButton.style.display = show ? 'flex' : 'none';
    }

    toggleVoice(show) {
        this.chatbotVoiceButton.style.display = show ? 'flex' : 'none';
    }

    setPromptPlaceholder(placeholder) {
        this.chatbotInputElement.placeholder = placeholder;
    }

    async handleSelectedFiles(event) {
        const conversationId = this.getAttribute('data-conversation');

        /** @type {HTMLInputElement} */
        const target = event.target;
        if (target.files && target.files.length > 0) {
            this.attachedFilesElement.classList.remove('empty');

            for await (const file of target.files) {
                const formData = new FormData();
                formData.append('file', file, file.name);

                const element = document.createElement('lakimi-attached-file');
                element.setAttribute('data-type', file.type);
                element.setAttribute('data-name', file.name);
                element.setAttribute('data-uploading', 'true')
                element.setAttribute('data-deletable', 'true');
                element.addEventListener('remove', (event) => {
                    const fileId = event.detail;
                    this.attachedFiles = this.attachedFiles.filter(file => file.id !== fileId);
                    element.remove();
                });
                this.attachedFilesElement.appendChild(element);

                const response = await fetch(`${this.apiEndpoint}/assistant/conversation/${conversationId}/attachment`, {
                    method: 'POST',
                    body: formData
                });

                const data = await response.json();
                this.attachedFiles.push(data);

                element.setAttribute('data-file', data.id);
                element.setAttribute('data-name', data.filename);
                element.setAttribute('data-type', data.type);
                element.setAttribute('data-uploading', 'false')
            }
        }
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.sendMessage();
        }
    }

    handleActiveVoice(event) {
        this.dispatchEvent(new CustomEvent('voice'));
    }

    sendMessage() {
        const message = this.chatbotInputElement.value;
        if (message) {
            this.dispatchEvent(new CustomEvent('prompt', { detail: { message, files: this.attachedFiles } }));
            this.chatbotInputElement.value = '';
            this.attachedFilesElement.innerHTML = '';
            this.attachedFilesElement.classList.add('empty');
            this.attachedFiles = [];
        }
    }

    attachFiles() {
        this.inputFilesElement.click();
    }
}

customElements.define('lakimi-chatbot-prompt', LakimiChatbotPrompt);